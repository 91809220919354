let leafletPromise = null;
let isInitialized = false;

export async function loadMapDependencies() {
  if (isInitialized) {
    return { L: window.L };
  }
  
  if (!leafletPromise) {
    leafletPromise = import("leaflet").then(module => {
      import("leaflet/dist/leaflet.css");
      return module.default || module;
    });
  }

  const [L] = await Promise.all([leafletPromise]);
  
  window.L = L;
  
  isInitialized = true;
  return { L };
}
